<template>
  <ChatMessagesList />
</template>

<script>
import ChatMessagesList from "@/components/chats/ChatMessagesList"

export default {
  name: "Chat",
  components: {
    ChatMessagesList,
  },
}
</script>

<style scoped></style>
