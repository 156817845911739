<template>
  <div>
    <a-list
      class="comment-list"
      :header="currentChat.name"
      item-layout="horizontal"
      :data-source="currentChat.messages"
    >
      <a-list-item slot="renderItem" slot-scope="item">
        <a-comment>
          <span slot="author">
            {{ item.user_name }}, {{ item.client_name }}{{ item.dealer_name
            }}{{ item.company_name }}
            <a-tooltip :title="currentChat.is_read ? 'Прочитано' : 'Новое'">
              <a-icon
                type="message"
                :style="{ color: item.is_read ? '' : '#f00' }"
              />
            </a-tooltip>
          </span>
          <a-tooltip
            slot="datetime"
            :title="moment(item.created_at).format('DD.MM.YYYY HH:mm:ss')"
          >
            <span>{{ moment(item.created_at).fromNow() }}</span>
          </a-tooltip>
          <a-avatar slot="avatar" icon="user" />
          <p slot="content">
            {{ item.message }}
          </p>
          <template slot="actions">
            <a-tag :color="item.is_read_dc ? 'geekblue' : ''">D</a-tag>
            <a-tag :color="item.is_read_imp ? 'geekblue' : ''">M</a-tag>
            <a-tag :color="item.is_read_sal ? 'geekblue' : ''">S</a-tag>
          </template>
        </a-comment>
      </a-list-item>

      <a-form-model
        ref="frmCreateMessage"
        :model="chatMessage"
        :rules="messageRules"
      >
        <a-form-model-item prop="message">
          <a-textarea
            v-model="chatMessage.message"
            placeholder="Ваше сообщение"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            type="primary"
            :loading="frmCreateMessageLoading"
            @click="onCreateMessageSubmit"
          >
            Ответить
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-list>
  </div>
</template>

<script>
import moment from "moment/moment"
import { mapActions, mapGetters } from "vuex"

moment.locale("ru")

export default {
  name: "ChatMessagesList",

  data() {
    return {
      moment,
      chatMessage: { parent_id: null, message: "" },
      messageRules: {
        message: [
          {
            required: true,
            message: "Сообщение обязательно",
            trigger: "change",
          },
        ],
      },
      frmCreateMessageLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      isBuyer: "auth/isBuyer",
      isDealer: "auth/isDealer",
      currentChat: "chats/currentChat",
    }),
  },

  mounted() {
    this.getChat(this.$route.params.id)
  },

  methods: {
    ...mapActions({
      getChat: "chats/getChat",
      createMessage: "chats/createMessage",
    }),
    onCreateMessageSubmit() {
      this.$refs.frmCreateMessage.validate(valid => {
        if (valid) {
          this.chatMessage.parent_id =
            this.currentChat.messages[this.currentChat.messages.length - 1].id
          this.createMessage(this.chatMessage)
          this.$refs.frmCreateMessage.resetFields()
        }
      })
    },
  },
}
</script>

<style scoped>
.ant-comment-actions > li > span {
  padding-right: 7px;
  margin-right: 2px;
}

.ant-tag-geekblue {
  color: #fff;
  background: #5d7d2b;
  border-color: #5d7d2b;
}
</style>
